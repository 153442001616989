<div class="auth-page-center">
  <div *ngIf="step === 0" class="w-100">
    <h1>{{ "APP.AUTH.LOGIN" | translate }}</h1>
    <div class="subheader subheader-sm">
      {{ "APP.AUTH.NICE_TO_SEE_YOU" | translate }}
    </div>

    <div class="form-380">
      <app-login-form
        [selectedOption]="selectedOption"
        [invitedCoHostEmail]="invitedCoHostEmail"
        (setStepEvent)="setStep($event)"
      ></app-login-form>

      <!-- <div class="auth-fallback-container">
        <span class="text-grey"
          >{{ "APP.AUTH.FORGOT_PASSWORD" | translate }}</span
        >
        <span class="text-link" (click)="goToForgotPassword()">
          {{ "APP.AUTH.RESET_PASSWORD" | translate }}
        </span>
      </div> -->

      <div class="auth-fallback-container">
        <span class="text-grey">{{
          "APP.AUTH.NO_ACCOUNT_FALLBACK" | translate
        }}</span>
        <span class="text-link" (click)="goToSignUp()">
          {{ "APP.AUTH.CREATE_ACCOUNT_SHORT" | translate }}
        </span>
      </div>

      @if (
        env.platformShort === "eventpage" || env.platformShort === "weddingpage"
      ) {
        <div class="hr-text">
          <span>{{ "APP.OR" | translate }}</span>
        </div>

        <app-sso-buttons></app-sso-buttons>
      }

      <!--      <app-h-captcha-info></app-h-captcha-info>-->
    </div>
  </div>

  <div *ngIf="step === 1 && selectedOption === 'email'">
    <app-login-step2
      [isAuthDialog]="true"
      [email]="userEmail"
      (success)="onSuccess()"
      class="auth-step-page-center"
    ></app-login-step2>
  </div>

  <div *ngIf="step === 1 && selectedOption === 'phone'">
    <app-verify-phone-dialog
      [phone]="userPhone"
      [otpUserId]="userId"
      [isWrongCode]="isWrongCode"
      (changePhone)="changePhone()"
      (authLoginEvent)="authLoginEvent($event)"
    >
    </app-verify-phone-dialog>
  </div>
</div>
